<template>
  <v-dialog class="datepicker" v-model="dialog" persistent>
    <v-date-picker
      v-model="date"
      :max="max"
      :min="min"
      no-title
      width="100%"
      color="#1cc2a6"
    >
      <v-btn class="datepicker__btn--ok" @click="close(true)" text>được rồi</v-btn>
      <v-btn class="datepicker__btn--cancel" @click="close(false)" text>Hủy</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    max: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      date: null
    }
  },
  methods: {
    /**
     * このダイアログを開く
     */
    open () {
      this.dialog = true
    },
    /**
     * このダイアログを閉じる
     * @param {Boolean} isSelected 日付を選択したかどうか
     */
    close (isSelected) {
      this.$emit('setDate', isSelected ? this.date : null)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.datepicker {
  &__btn {
    display: block;
    margin: 0 auto;
    &--ok {
      @extend .datepicker__btn;
      color: #1cc2a6;
    }
    &--cancel {
      @extend .datepicker__btn;
      color: #1cc2a6;
    }
  }
}
</style>
